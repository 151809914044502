import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendar,
  faCodeBranch,
  faEdit,
} from '@fortawesome/free-solid-svg-icons';
import { withAppHOC } from '../../hoc';
import PageHeading from '../../components/PageHeader/PageHeader';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../components/NoData/NoData';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  CreateButton,
  MessageBox,
  Dropdown,
  CustomButton,
} from '../../components/FormElements';
import Modal from '../../components/Modal';
import OverlayLoader from '../../components/OverlayLoader/OverlayLoader';
import { TableBuilder } from '../../components/TableElements';
import { StyledLink, StyledModalContainer } from '../../components/Styled';
import Pagination from '../../components/Pagination/Pagination';
import API from '../../api';
import { API_RESPONSE_TYPES } from '../../constants';
import { getSearchParams } from '../../utils';

const HostsRead = (props) => {
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams('pageNumber') || 1,
    recordsPerPage: getSearchParams('recordsPerPage') || 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [hosts, setHosts] = useState([]);
  const [packages, setPackages] = useState([]);
  const [hostsId, setHostsId] = useState('');
  const [packagesId, setPackagesId] = useState('');
  const [showModal, setShowModal] = useState(false);

  const readPackages = () => {
    API.get('/packages').then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setPackages(data);
      }
    });
  };

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(
      `/hosts/?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}`
    )
      .then((response) => {
        const {
          status,
          message,
          data,
          pageInfo: { totalRecords },
        } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setTotalRecords(totalRecords);
          setHosts(data);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [paginationData]);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.put(`/hosts/extend/${hostsId}`, {
      packagesId,
    })
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          reloadData();
          setTimeout(() => {
            setShowModal(false);
            setPackagesId('');
            setHostsId('');
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const reloadData = () => {
    readData();
  };

  useEffect(() => {
    readData();
  }, [paginationData, readData]);

  useEffect(() => {
    readPackages();
  }, []);

  const packageOptions = [{ title: '-- SELECT PACKAGE --', value: '' }];
  packages.forEach(({ id, packageName }) =>
    packageOptions.push({
      title: packageName,
      value: id,
    })
  );

  return (
    <>
      <PageHeading
        {...props}
        canCreate={true}
        createLink={'create'}
        createTitle='Create Host'
        canReload={true}
        onClickReload={reloadData}
      />
      <LoadingSpinner
        isLoading={responseStatus === '' && hosts.length === 0 && isLoading}
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          hosts.length === 0
        }
        message={`No hosts found`}
      />
      {responseStatus === API_RESPONSE_TYPES.FAILURE && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {hosts.length > 0 && (
        <>
          <TableBuilder
            isLoading={hosts.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: '',
                dataSelector: 'id',
                align: 'center',
                sticky: true,
                canSort: false,
                width: '100px',
                CellRenderer: (value) => (
                  <>
                    <StyledLink to={`update/${value}`} title={'Update Host'}>
                      <FontAwesomeIcon icon={faEdit} />
                    </StyledLink>
                    <StyledLink
                      to={`/roles-and-permissions/${value}`}
                      title={'Roles & Permissions'}
                    >
                      <FontAwesomeIcon
                        icon={faCodeBranch}
                        style={{ color: 'orange' }}
                      />
                    </StyledLink>
                    <CustomButton
                      icon={faCalendar}
                      onClick={() => {
                        setHostsId(value);
                        setShowModal(true);
                      }}
                      style={{ color: 'green' }}
                    />
                  </>
                ),
              },
              {
                title: 'App Name',
                dataSelector: 'appName',
                dataType: 'string',
              },
              {
                title: 'Domain',
                dataSelector: 'domain',
                dataType: 'string',
              },
              {
                title: 'Package Expiry Date',
                dataSelector: 'packageExpiryDate',
                dataType: 'date',
                CellRenderer: (value) => (value ? value : 'N/A'),
              },
            ]}
            tableData={hosts}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
          <Modal
            modalStatus={showModal}
            setModalStatus={() => {
              setShowModal(false);
              setHostsId('');
              setPackagesId('');
            }}
            hideCloseButton
          >
            <StyledModalContainer width={'500px'}>
              <h3>Extend Plan</h3>
              <FormContainer>
                <Form method='POST' action='#' onSubmit={onSubmit}>
                  <Fieldset>
                    <Label required>Package</Label>
                    <Dropdown
                      placeholder='Please select package'
                      value={packagesId}
                      onChange={setPackagesId}
                      options={packageOptions}
                      disabled={isLoading}
                    />
                  </Fieldset>
                  {showModal && responseStatus !== '' && (
                    <Fieldset>
                      <MessageBox
                        status={responseStatus}
                        message={responseMessage}
                      />
                    </Fieldset>
                  )}
                  <Fieldset>
                    <CreateButton
                      disabled={isLoading}
                      type='submit'
                      style={{ width: 'auto' }}
                    >
                      Submit
                    </CreateButton>
                  </Fieldset>
                  <OverlayLoader showLoader={showModal && isLoading} />
                </Form>
              </FormContainer>
            </StyledModalContainer>
          </Modal>
        </>
      )}
    </>
  );
};

export default withAppHOC(HostsRead);
