import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { withAppHOC } from "../../hoc";
import PageHeading from "../../components/PageHeader/PageHeader";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../components/NoData/NoData";
import {
  MessageBox,
  Form,
  Fieldset,
  Button,
  ResetButton,
  TextInput,
  Label,
  CustomConfirmButton,
} from "../../components/FormElements";
import { TableBuilder } from "../../components/TableElements";
import FiltersButton from "../../components/FiltersButton/FiltersButton";
import Pagination from "../../components/Pagination/Pagination";
import { StyledLink, StyledFiltersContainer } from "../../components/Styled";
import API from "../../api";
import { API_RESPONSE_TYPES } from "../../constants";
import {
  camelCaseToString,
  getSearchParams,
  redirectToPageOne,
  shouldRedirect,
} from "../../utils";

const RolesAndPermissionsRead = (props) => {
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams("pageNumber") || 1,
    recordsPerPage: getSearchParams("recordsPerPage") || 10,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [rolesAndPermissions, setRolesAndPermissions] = useState([]);
  const [roleName, setRoleName] = useState("");
  const { hostid } = useParams();

  const resetForm = () => {
    setRoleName("");
    readData();
  };

  const readData = useCallback(
    (roleName = "") => {
      setIsLoading(true);
      setResponseStatus("");
      setResponseMessage("");
      API.get(
        `/roles-and-permissions/?hostId=${hostid}&pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&roleName=${roleName}`
      )
        .then((response) => {
          const { status, message, data, pageInfo } = response.data;
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            if (status === API_RESPONSE_TYPES.FAILURE) {
              setResponseStatus(status);
              setResponseMessage(message);
            }
            setTotalRecords(pageInfo.totalRecords);
            setRolesAndPermissions(data);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [paginationData, hostid]
  );

  const deleteData = (id) => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.delete(
      `/roles-and-permissions/${id}?hostId=${hostid}&pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&roleName=${roleName}`
    )
      .then((response) => {
        const { status, message } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          reloadData();
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const reloadData = () => {
    readData(roleName);
  };

  const filterData = (e) => {
    e.preventDefault();
    setIsFiltering(true);
  };

  useEffect(() => {
    if (isFiltering) {
      setPaginationData((paginationData) => ({
        pageNumber: 1,
        recordsPerPage: paginationData.recordsPerPage,
      }));
      setIsFiltering((isFiltering) => !isFiltering);
    }
  }, [isFiltering]);

  useEffect(() => {
    if (!isFiltering) {
      readData(roleName);
    }
  }, [paginationData, readData]);

  const [showFilters, setShowFilters] = useState(false);
  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  return (
    <>
      <PageHeading
        {...props}
        canCreate={true}
        createLink="create"
        createTitle="Create Roles & Permission"
        canReload={true}
        onClickReload={reloadData}
      />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? "show" : ""}>
        <Form method="POST" action="#" onSubmit={filterData}>
          <Fieldset>
            <Label>Role Name</Label>
            <TextInput
              value={roleName}
              onChange={setRoleName}
              placeholder="Role Name"
              maxLength={100}
              disabled={isLoading === true}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading === true} type="submit">
              Filter
            </Button>
            <ResetButton
              disabled={isLoading === true}
              type="button"
              onClick={resetForm}
            >
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={
          responseStatus === "" && rolesAndPermissions.length === 0 && isLoading
        }
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          rolesAndPermissions.length === 0
        }
        message={`No roles and permissions found`}
      />
      {responseStatus === API_RESPONSE_TYPES.FAILURE && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {rolesAndPermissions.length > 0 && (
        <>
          <TableBuilder
            isLoading={rolesAndPermissions.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: "",
                dataSelector: "id",
                sticky: true,
                canSort: false,
                width: "100px",
                CellRenderer: (value) => (
                  <>
                    <StyledLink to={`update/${value}`} title={`Update Role`}>
                      <FontAwesomeIcon icon={faEdit} />
                    </StyledLink>
                    <CustomConfirmButton onClick={() => deleteData(value)} />
                  </>
                ),
              },
              {
                title: "Role Name",
                dataSelector: "roleName",
                dataType: "string",
                CellRenderer: (value) => camelCaseToString(value),
              },
              {
                title: "Default Role",
                dataSelector: "isDefaultRole",
                dataType: "string",
                align: "center",
              },
            ]}
            tableData={rolesAndPermissions}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
        </>
      )}
    </>
  );
};

export default withAppHOC(RolesAndPermissionsRead);
