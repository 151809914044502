import React, { useState, useEffect, useCallback } from 'react';
import { withAppHOC } from '../../hoc';
import PageHeading from '../../components/PageHeader/PageHeader';
import OverlayLoader from '../../components/OverlayLoader/OverlayLoader';
import { Grid, GridItem } from '../../components/Grid';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  NumberInput,
  MessageBox,
  UpdateButton,
  ResetButton,
} from '../../components/FormElements';
import API from '../../api';
import { API_RESPONSE_TYPES } from '../../constants';

const Profile = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [originalFormData, setOriginalFormData] = useState({});
  const [editable, setEditable] = useState(false);

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    const { firstName, lastName, mobileNumber } = originalFormData;
    setFirstName(firstName);
    setLastName(lastName);
    setMobileNumber(mobileNumber);
  };

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/auth/profile`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const { firstName, lastName, mobileNumber } = data;
          setFirstName(firstName);
          setLastName(lastName);
          setMobileNumber(mobileNumber);
          setOriginalFormData({
            firstName,
            lastName,
            mobileNumber,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.put(`/auth/profile`, {
      firstName,
      lastName,
    })
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalFormData((originalFormData) => ({
            ...originalFormData,
            firstName,
            lastName,
          }));
          setEditable(false);
          setTimeout(() => {
            resetResponseData();
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    readData();
  }, [readData]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          <Grid
            columns={
              'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
            }
          >
            <GridItem>
              <Fieldset>
                <Label required>First Name</Label>
                <TextInput
                  placeholder='Please enter your first name'
                  value={firstName}
                  onChange={setFirstName}
                  maxLength={100}
                  autoComplete='off'
                  disabled={!editable || isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Last Name</Label>
                <TextInput
                  placeholder='Please enter your last name'
                  value={lastName}
                  onChange={setLastName}
                  maxLength={100}
                  autoComplete='off'
                  disabled={!editable || isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Mobile Number</Label>
                <NumberInput
                  placeholder='Please enter your mobile number'
                  value={mobileNumber}
                  onChange={setMobileNumber}
                  maxLength={10}
                  autoComplete='off'
                  disabled={true}
                  pattern={`[0-9]*`}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          {!editable ? (
            <Fieldset>
              <UpdateButton
                type='button'
                onClick={(e) => {
                  e.preventDefault();
                  setEditable((editable) => !editable);
                }}
              >
                Edit
              </UpdateButton>
            </Fieldset>
          ) : (
            <Fieldset>
              <UpdateButton disabled={isLoading === true} type='submit'>
                Update
              </UpdateButton>
              <ResetButton
                disabled={isLoading === true}
                type='button'
                onClick={resetForm}
              >
                Reset
              </ResetButton>
            </Fieldset>
          )}
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(Profile);
