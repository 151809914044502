import React, { useState, useEffect } from "react";
import { withAppHOC } from "../../hoc";
import PageHeading from "../../components/PageHeader/PageHeader";
import OverlayLoader from "../../components/OverlayLoader/OverlayLoader";
import { Grid, GridItem } from "../../components/Grid";
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  Legend,
  FileInput,
  TextInput,
  NumberInput,
  PasswordInput,
  Dropdown,
  MessageBox,
  CreateButton,
  ResetButton,
} from "../../components/FormElements";
import API from "../../api";
import { API_RESPONSE_TYPES } from "../../constants";
import { getBase64 } from "../../utils";

const HostsCreate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [packages, setPackages] = useState([]);
  const [domain, setDomain] = useState("");
  const [appName, setAppName] = useState("");
  const [logo, setLogo] = useState("");
  const [favicon, setFavicon] = useState("");
  const [homeBanner, setHomeBanner] = useState("");
  const [dbHostname, setDbHostname] = useState("");
  const [dbDatabase, setDbDatabase] = useState("");
  const [dbUsername, setDbUsername] = useState("");
  const [dbPassword, setDbPassword] = useState("");
  const [adminMobileNumber, setAdminMobileNumber] = useState("");
  const [adminPassword, setAdminPassword] = useState("");
  const [packagesId, setPackagesId] = useState("");
  const [logoBase64, setLogoBase64] = useState("");
  const [faviconBase64, setFaviconBase64] = useState("");
  const [homebannerBase64, setHomebannerBase64] = useState("");

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus("");
    setResponseMessage("");
  };

  const resetForm = () => {
    setDomain("");
    setAppName("");
    setLogo([]);
    setFavicon([]);
    setHomeBanner([]);
    setDbHostname("");
    setDbDatabase("");
    setDbUsername("");
    setDbPassword("");
    setAdminMobileNumber("");
    setAdminPassword("");
    setPackagesId("");
  };

  const readPackages = () => {
    API.get("/packages").then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setPackages(data);
      }
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.post("/hosts", {
      domain,
      appName,
      db_host: dbHostname,
      db_database: dbDatabase,
      db_username: dbUsername,
      db_password: dbPassword,
      adminNumber: adminMobileNumber,
      adminPassword: adminPassword,
      packagesId: packagesId,
      logo: logoBase64,
      favicon: faviconBase64,
      homeBanner: homebannerBase64,
    })
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          resetForm();
          setTimeout(() => {
            resetResponseData();
            window.location.href = '/hosts';
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    readPackages();
  }, []);

  useEffect(() => {
    if (logo?.length > 0) {
      getBase64(logo[0], setLogoBase64);
    }
  }, [logo]);

  useEffect(() => {
    if (favicon?.length > 0) {
      getBase64(favicon[0], setFaviconBase64);
    }
  }, [favicon]);

  useEffect(() => {
    if (homeBanner?.length > 0) {
      getBase64(homeBanner[0], setHomebannerBase64);
    }
  }, [homeBanner]);

  const packageOptions = [{ title: "-- SELECT PACKAGE --", value: "" }];
  packages.forEach(({ id, packageName }) =>
    packageOptions.push({
      title: packageName,
      value: id,
    })
  );

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form
          method="POST"
          action="#"
          onSubmit={onSubmit}
          enctype="multipart/form-data"
        >
          <Fieldset forLegend>
            <Legend>App Configuration</Legend>
            <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
              <GridItem>
                <Fieldset>
                  <Label required>Domain</Label>
                  <TextInput
                    value={domain}
                    onChange={setDomain}
                    placeholder="Please enter domain"
                    disabled={isLoading}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label required>App Name</Label>
                  <TextInput
                    value={appName}
                    onChange={setAppName}
                    placeholder="Please enter app name"
                    disabled={isLoading}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Grid
              columns={
                "calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)"
              }
            >
              <GridItem>
                <Fieldset>
                  <Label>Logo</Label>
                  <FileInput
                    files={logo}
                    onChange={setLogo}
                    accept="image/*"
                    disabled={isLoading}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Favicon</Label>
                  <FileInput
                    files={favicon}
                    onChange={setFavicon}
                    accept="image/*"
                    disabled={isLoading}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label>Home Banner</Label>
                  <FileInput
                    files={homeBanner}
                    onChange={setHomeBanner}
                    accept="image/*"
                    disabled={isLoading}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
          </Fieldset>
          <Fieldset forLegend>
            <Legend>Database Configuration</Legend>
            <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
              <GridItem>
                <Fieldset>
                  <Label required>Hostname Or IP Address</Label>
                  <TextInput
                    value={dbHostname}
                    onChange={setDbHostname}
                    placeholder="Please enter hostname"
                    disabled={isLoading}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label required>Database Name</Label>
                  <TextInput
                    value={dbDatabase}
                    onChange={setDbDatabase}
                    placeholder="Please enter database name"
                    disabled={isLoading}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
              <GridItem>
                <Fieldset>
                  <Label required>Username</Label>
                  <TextInput
                    value={dbUsername}
                    onChange={setDbUsername}
                    placeholder="Please enter username"
                    disabled={isLoading}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label required>Password</Label>
                  <PasswordInput
                    value={dbPassword}
                    onChange={setDbPassword}
                    placeholder="Please enter password"
                    disabled={isLoading}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
          </Fieldset>
          <Fieldset forLegend>
            <Legend>Admin Account Configuration</Legend>
            <Grid columns={"calc(50% - 10px) calc(50% - 10px)"}>
              <GridItem>
                <Fieldset>
                  <Label required>Admin Mobile Number</Label>
                  <NumberInput
                    value={adminMobileNumber}
                    onChange={setAdminMobileNumber}
                    placeholder="Please enter admin mobile number"
                    disabled={isLoading}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label required>Admin Password</Label>
                  <PasswordInput
                    value={adminPassword}
                    onChange={setAdminPassword}
                    placeholder="Please enter admin password"
                    disabled={isLoading}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
          </Fieldset>
          <Fieldset forLegend>
            <Legend>Select Package</Legend>
            <Fieldset>
              <Label required>Package</Label>
              <Dropdown
                placeholder="Please select package"
                value={packagesId}
                onChange={setPackagesId}
                options={packageOptions}
                disabled={isLoading}
              />
            </Fieldset>
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <CreateButton disabled={isLoading} type="submit">
              Create
            </CreateButton>
            <ResetButton disabled={isLoading} type="button" onClick={resetForm}>
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(HostsCreate);
