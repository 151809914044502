import {
  faHome,
  faServer,
  faGears,
  faCubes,
} from '@fortawesome/free-solid-svg-icons';
import { ROUTE_TYPES, VIEW_TYPES } from './index';
import Dashboard from '../containers/Dashboard/Dashboard';
import Login from '../containers/Auth/Login';
import ForgotPassword from '../containers/Auth/ForgotPassword';
import Profile from '../containers/Auth/Profile';
import ChangePassword from '../containers/Auth/ChangePassword';
import HostsCreate from '../containers/Hosts/HostsCreate';
import HostsRead from '../containers/Hosts/HostsRead';
import HostsUpdate from '../containers/Hosts/HostsUpdate';
import Configurations from '../containers/Configurations/Configurations';
import PackagesCreate from '../containers/Packages/PackagesCreate';
import PackagesRead from '../containers/Packages/PackagesRead';
import PackagesUpdate from '../containers/Packages/PackagesUpdate';
import RolesAndPermissionsCreate from '../containers/RolesAndPermissions/RolesAndPermissionsCreate';
import RolesAndPermissionsRead from '../containers/RolesAndPermissions/RolesAndPermissionsRead';
import RolesAndPermissionsUpdate from '../containers/RolesAndPermissions/RolesAndPermissionsUpdate';
import Documentation from '../containers/Documentation/Documentation';

const { ALLOW_IF_LOGGEDIN, ALLOW_IF_NOT_LOGGEDIN } = ROUTE_TYPES;
const { TABLE, GRID } = VIEW_TYPES;

const ROUTES = [
  {
    path: '/',
    title: 'Dashboard',
    icon: faHome,
    routeType: ALLOW_IF_LOGGEDIN,
    component: Dashboard,
    hideHeading: true,
    hideBreadcrumbs: true,
  },
  {
    path: '/login',
    title: 'Login',
    routeType: ALLOW_IF_NOT_LOGGEDIN,
    component: Login,
    hideInNavigation: true,
  },
  {
    path: '/forgot-password',
    title: 'Forgot Password',
    routeType: ALLOW_IF_NOT_LOGGEDIN,
    component: ForgotPassword,
    hideInNavigation: true,
  },
  {
    path: '/profile',
    title: 'Profile',
    routeType: ALLOW_IF_LOGGEDIN,
    component: Profile,
    hideInNavigation: true,
  },
  {
    path: '/change-password',
    title: 'Change Password',
    routeType: ALLOW_IF_LOGGEDIN,
    component: ChangePassword,
    hideInNavigation: true,
  },
  {
    path: '/hosts',
    title: 'Hosts',
    icon: faServer,
    routeType: ALLOW_IF_LOGGEDIN,
    component: HostsRead,
    viewType: TABLE,
    subRoutes: [
      {
        path: '/hosts/create',
        title: 'Create Host',
        routeType: ALLOW_IF_LOGGEDIN,
        component: HostsCreate,
        viewType: GRID,
        hideInNavigation: true,
      },
      {
        path: '/hosts/update/:id',
        title: 'Update Host',
        routeType: ALLOW_IF_LOGGEDIN,
        component: HostsUpdate,
        viewType: GRID,
        hideInNavigation: true,
      },
    ],
  },
  {
    path: '/configurations',
    title: 'Configurations',
    icon: faGears,
    routeType: ALLOW_IF_LOGGEDIN,
    component: Configurations,
    viewType: GRID,
  },
  {
    path: '/packages',
    title: 'Packages',
    icon: faCubes,
    routeType: ALLOW_IF_LOGGEDIN,
    component: PackagesRead,
    viewType: TABLE,
    subRoutes: [
      {
        path: '/packages/create',
        title: 'Create Package',
        routeType: ALLOW_IF_LOGGEDIN,
        component: PackagesCreate,
        viewType: GRID,
        hideInNavigation: true,
      },
      {
        path: '/packages/update/:id',
        title: 'Update Package',
        routeType: ALLOW_IF_LOGGEDIN,
        component: PackagesUpdate,
        viewType: GRID,
        hideInNavigation: true,
      },
    ],
  },
  {
    path: '/roles-and-permissions/:hostid',
    title: 'Roles & Permissions',
    routeType: ALLOW_IF_LOGGEDIN,
    component: RolesAndPermissionsRead,
    viewType: TABLE,
    hideInNavigation: true,
    subRoutes: [
      {
        path: '/roles-and-permissions/:hostid/create',
        title: 'Create Roles & Permissions',
        routeType: ALLOW_IF_LOGGEDIN,
        component: RolesAndPermissionsCreate,
        viewType: GRID,
        hideInNavigation: true,
      },
      {
        path: '/roles-and-permissions/:hostid/update/:id',
        title: 'Update Roles & Permissions',
        routeType: ALLOW_IF_LOGGEDIN,
        component: RolesAndPermissionsUpdate,
        viewType: GRID,
        hideInNavigation: true,
      },
    ],
  },
  {
    path: '/documentation',
    title: 'Documentation',
    routeType: ALLOW_IF_LOGGEDIN,
    component: Documentation,
    viewType: TABLE,
  },
];

export { ROUTES };
