import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { withAppHOC } from '../../hoc';
import PageHeading from '../../components/PageHeader/PageHeader';
import OverlayLoader from '../../components/OverlayLoader/OverlayLoader';
import { Grid, GridItem } from '../../components/Grid';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  Legend,
  TextInput,
  PasswordInput,
  MessageBox,
  UpdateButton,
  ResetButton,
} from '../../components/FormElements';
import API from '../../api';
import { API_RESPONSE_TYPES } from '../../constants';
import { StyledSpan } from '../../components/Styled';

const HostsUpdate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [domain, setDomain] = useState('');
  const [appName, setAppName] = useState('');
  const [dbHostname, setDbHostname] = useState('');
  const [dbDatabase, setDbDatabase] = useState('');
  const [dbUsername, setDbUsername] = useState('');
  const [dbPassword, setDbPassword] = useState('');
  const [adminPassword, setAdminPassword] = useState('');
  const [originalFormData, setOriginalFormData] = useState({});
  const { id } = useParams();

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    const { domain, appName, dbHostname, dbDatabase, dbUsername, dbPassword } =
      originalFormData;
    setDomain(domain);
    setAppName(appName);
    setDbHostname(dbHostname);
    setDbDatabase(dbDatabase);
    setDbUsername(dbUsername);
    setDbPassword(dbPassword);
    setAdminPassword('');
  };

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/hosts/${id}`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const {
            domain,
            appName,
            db_host: dbHostname,
            db_database: dbDatabase,
            db_username: dbUsername,
            db_password: dbPassword,
          } = data[0];
          setDomain(domain);
          setAppName(appName);
          setDbHostname(dbHostname);
          setDbDatabase(dbDatabase);
          setDbUsername(dbUsername);
          setDbPassword(dbPassword);
          setOriginalFormData({
            domain,
            appName,
            dbHostname,
            dbDatabase,
            dbUsername,
            dbPassword,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.put(`/hosts/${id}`, {
      domain,
      appName,
      db_host: dbHostname,
      db_database: dbDatabase,
      db_username: dbUsername,
      db_password: dbPassword,
      adminPassword,
    })
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalFormData({
            domain,
            appName,
            dbHostname,
            dbDatabase,
            dbUsername,
            dbPassword,
          });
          setTimeout(() => {
            resetResponseData();
            window.location.href = '/hosts';
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    readData();
  }, [readData]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          <Fieldset forLegend>
            <Legend>App Configuration</Legend>
            <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
              <GridItem>
                <Fieldset>
                  <Label required>Domain</Label>
                  <TextInput
                    value={domain}
                    onChange={setDomain}
                    placeholder='Please enter domain'
                    disabled={isLoading}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label required>App Name</Label>
                  <TextInput
                    value={appName}
                    onChange={setAppName}
                    placeholder='Please enter app name'
                    disabled={isLoading}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
          </Fieldset>
          <Fieldset forLegend>
            <Legend>Database Configuration</Legend>
            <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
              <GridItem>
                <Fieldset>
                  <Label required>Hostname Or IP Address</Label>
                  <TextInput
                    value={dbHostname}
                    onChange={setDbHostname}
                    placeholder='Please enter hostname'
                    disabled={isLoading}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label required>Database Name</Label>
                  <TextInput
                    value={dbDatabase}
                    onChange={setDbDatabase}
                    placeholder='Please enter database name'
                    disabled={isLoading}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
            <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
              <GridItem>
                <Fieldset>
                  <Label required>Username</Label>
                  <TextInput
                    value={dbUsername}
                    onChange={setDbUsername}
                    placeholder='Please enter username'
                    disabled={isLoading}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label required>Password</Label>
                  <PasswordInput
                    value={dbPassword}
                    onChange={setDbPassword}
                    placeholder='Please enter password'
                    disabled={isLoading}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
          </Fieldset>
          <Fieldset>
            <Label>Admin Password</Label>
            <StyledSpan style={{ color: 'red', marginBottom: '10px' }}>
              Note : Please enter only this to change admin password or unblock
              admins account.
            </StyledSpan>
            <PasswordInput
              value={adminPassword}
              onChange={setAdminPassword}
              placeholder='Please enter admin password'
              disabled={isLoading}
            />
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <UpdateButton disabled={isLoading} type='submit'>
              Update
            </UpdateButton>
            <ResetButton disabled={isLoading} type='button' onClick={resetForm}>
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(HostsUpdate);
