import React, { useState } from 'react';
import { withAppHOC } from '../../hoc';
import PageHeading from '../../components/PageHeader/PageHeader';
import OverlayLoader from '../../components/OverlayLoader/OverlayLoader';
import { Grid, GridItem } from '../../components/Grid';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  NumberInput,
  MessageBox,
  CreateButton,
  ResetButton,
} from '../../components/FormElements';
import API from '../../api';
import { API_RESPONSE_TYPES } from '../../constants';

const PackagesCreate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [packageName, setPackageName] = useState('');
  const [duration, setDuration] = useState('');

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    setPackageName('');
    setDuration('');
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.post('/packages', {
      packageName,
      duration
    })
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          resetForm();
          setTimeout(() => {
            resetResponseData();
            window.location.href = '/packages';
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label required>Package Name</Label>
                <TextInput
                  placeholder='Please enter package name'
                  value={packageName}
                  onChange={setPackageName}
                  maxLength={200}
                  autoComplete='off'
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Duration</Label>
                <NumberInput
                  placeholder='Please enter plan duration in days'
                  value={duration}
                  onChange={setDuration}
                  autoComplete='off'
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <CreateButton disabled={isLoading} type='submit'>
              Create
            </CreateButton>
            <ResetButton disabled={isLoading} type='button' onClick={resetForm}>
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(PackagesCreate);
