import isEmpty from 'lodash/isEmpty';
import { ROUTES } from '../constants/routes.config';

const authData = {
  get: () => {
    return {
      isLoggedIn: window.localStorage.getItem('isLoggedIn') === 'true' || false,
      authId: window.localStorage.getItem('authId') || null,
      authToken: window.localStorage.getItem('authToken') || null,
      accountData:
        JSON.parse(window.localStorage.getItem('accountData')) || null,
    };
  },
  set: (key, value) => window.localStorage.setItem(key, value),
  clear: () => window.localStorage.clear(),
};

const getPlatform = () => {
  return 'Web';
};

const getDeviceType = () => {
  return navigator?.userAgentData?.mobile ? 'Mobile' : 'Desktop';
};

const getOperatingSystem = () => {
  return navigator?.userAgentData?.platform ?? 'Unknown';
};

const generateRoutes = () => {
  const routesList = [];

  (function loopRoutes(routes) {
    !isEmpty(routes) &&
      routes.forEach((route) => {
        route.path && routesList.push(route);
        if (!isEmpty(route.subRoutes)) {
          loopRoutes(route.subRoutes);
        }
      });
  })(ROUTES);

  return routesList;
};

const isNumber = (event) => {
  event = event ? event : window.event;
  const charCode = event.which ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
};

const getSearchParams = (key) => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(key);
};

const shouldRedirect = (dataLength = 0, pageNumber = 1) => {
  return dataLength === 0 && parseInt(pageNumber) !== 1 ? true : false;
};

const redirectToPageOne = () => {
  window.location.href = window.location.href.split('?')?.[0];
};

const formatCurrency = (value) => {
  const rupeeIndian = Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  });

  return rupeeIndian.format(value);
};

const camelCaseToString = (text) => {
  const result = text?.replace(/([A-Z])/g, ' $1');
  return result?.charAt(0)?.toUpperCase() + result?.slice(1) || '';
};

const getBase64 = (file, cb) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    cb(reader.result);
  };
};

export {
  authData,
  getPlatform,
  getDeviceType,
  getOperatingSystem,
  generateRoutes,
  getSearchParams,
  shouldRedirect,
  redirectToPageOne,
  formatCurrency,
  isNumber,
  camelCaseToString,
  getBase64
};
