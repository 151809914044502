import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import GlobalStyles from './styles';
import { ROUTE_TYPES } from './constants';
import { authData, generateRoutes } from './utils';

const App = () => {
  const routesList = generateRoutes();
  const { ALLOW_IF_LOGGEDIN, ALLOW_IF_NOT_LOGGEDIN } = ROUTE_TYPES;
  const { isLoggedIn } = authData.get();
  const theme = {};

  const ProcessedRoute = ({ component: Component, routeType, ...rest }) => {
    let ProcessedComponent;

    if (!isEmpty(routeType)) {
      if (
        (routeType === ALLOW_IF_NOT_LOGGEDIN && !isLoggedIn) ||
        (routeType === ALLOW_IF_LOGGEDIN && isLoggedIn)
      ) {
        ProcessedComponent = (
          <Component routeType={!isEmpty(routeType) && routeType} {...rest} />
        );
      } else {
        ProcessedComponent = (
          <Navigate to={routeType === ALLOW_IF_LOGGEDIN ? '/login' : '/'} />
        );
      }
    } else {
      ProcessedComponent = <Component {...rest} />;
    }

    return ProcessedComponent;
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Router>
        <Routes>
          {routesList.map(
            ({ path, routeType, component, ...rest }, index) =>
              path && (
                <Route
                  path={path}
                  element={
                    <ProcessedRoute
                      routeType={routeType}
                      component={component}
                      {...rest}
                    />
                  }
                  key={index}
                />
              )
          )}
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
