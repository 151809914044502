const MODULES = {
  DASHBOARD: 'DASHBOARD',
  STATS: 'STATS',
  // Accounts Management
  ACCOUNTS_MANAGEMENT: 'ACCOUNTS_MANAGEMENT',
  ROLES_AND_PERMISSIONS: 'ROLES_AND_PERMISSIONS',
  ACCOUNTS: 'ACCOUNTS',
  VENDORS: 'VENDORS',
  // Configurations
  CONFIGURATIONS_MANAGEMENT: 'CONFIGURATIONS_MANAGEMENT',
  APP_META_DATA: 'APP_META_DATA',
  PAYMENT_GATEWAY_CONFIGURATION: 'PAYMENT_GATEWAY_CONFIGURATION',
  MESSAGING_CONFIGURATION: 'MESSAGING_CONFIGURATION',
  PUSH_NOTIFICATIONS_CONFIGURATION: 'PUSH_NOTIFICATIONS_CONFIGURATION',
  PUSH_NOTIFICATIONS: 'PUSH_NOTIFICATIONS',
  NOTIFICATIONS: 'NOTIFICATIONS',
  SCROLL_MESSAGE: 'SCROLL_MESSAGE',
  SMS_TEMPLATES: 'SMS_TEMPLATES',
  BANK_ACCOUNTS: 'BANK_ACCOUNTS',
  API_SETTINGS: 'API_SETTINGS',
  API_KEYS: 'API_KEYS',
  // Recharge Configurations
  RECHARGE_CONFIGURATIONS_MANAGEMENT: 'RECHARGE_CONFIGURATIONS_MANAGEMENT',
  SERVICES: 'SERVICES',
  CIRCLES: 'CIRCLES',
  OPERATORS: 'OPERATORS',
  API_PROVIDERS: 'API_PROVIDERS',
  OPERATORS_MAPPING: 'OPERATORS_MAPPING',
  API_SWITCHING: 'API_SWITCHING',
  STOCK_MANAGEMENT: 'STOCK_MANAGEMENT',
  ACCOUNTS_API_MAPPING: 'ACCOUNTS_API_MAPPING',
  ROLES_API_MAPPING: 'ROLES_API_MAPPING',
  INVALID_RECHARGE_AMOUNTS: 'INVALID_RECHARGE_AMOUNTS',
  ACCOUNT_OPERATOR_BLOCKERS: 'ACCOUNT_OPERATOR_BLOCKERS',
  BILL_FETCH_CONFIGURATION: 'BILL_FETCH_CONFIGURATION',
  COMMON_APIS_CONFIGURATION: 'COMMON_APIS_CONFIGURATION',
  // Margins
  MARGIN_TEMPLATES_MANAGEMENT: 'MARGIN_TEMPLATES_MANAGEMENT',
  MY_MARGINS: 'MY_MARGINS',
  MARGIN_TEMPLATES: 'MARGIN_TEMPLATES',
  ROLES_DEFAULT_MARGIN_TEMPLATES: 'ROLES_DEFAULT_MARGIN_TEMPLATES',
  // Transactions
  TRANSACTIONS_MANAGEMENT: 'TRANSACTIONS_MANAGEMENT',
  RECHARGE_TRANSACTIONS: 'RECHARGE_TRANSACTIONS',
  PENDING_RECHARGE_TRANSACTIONS: 'PENDING_RECHARGE_TRANSACTIONS',
  DISPUTED_RECHARGE_TRANSACTIONS: 'DISPUTED_RECHARGE_TRANSACTIONS',
  LIVE_RECHARGE_TRANSACTIONS: 'LIVE_RECHARGE_TRANSACTIONS',
  API_CALLBACK_LOGS: 'API_CALLBACK_LOGS',
  // Accounting
  ACCOUNTING_MANAGEMENT: 'ACCOUNTING_MANAGEMENT',
  WALLET_DEPOSIT_REQUESTS: 'WALLET_DEPOSIT_REQUESTS',
  PAYMENT_GATEWAY_DEPOSITS: 'PAYMENT_GATEWAY_DEPOSITS',
  VENDOR_PAYMENTS: 'VENDOR_PAYMENTS',
  VENDOR_GST_INVOICES: 'VENDOR_GST_INVOICES',
  ACCOUNT_GST_INVOICES: 'ACCOUNT_GST_INVOICES',
  GST_INVOICES_SUMMARY: 'GST_INVOICES_SUMMARY',
  API_PROVIDER_PAYMENTS: 'API_PROVIDER_PAYMENTS',
  // Reports
  REPORTS_MANAGEMENT: 'REPORTS_MANAGEMENT',
  OPERATORS_DAY_BOOK: 'OPERATORS_DAY_BOOK',
  API_SUMMARY_BOOK: 'API_SUMMARY_BOOK',
  ACCOUNTS_DAY_BOOK: 'ACCOUNTS_DAY_BOOK',
  USERS_OPERATORS_DAY_BOOK: 'USERS_OPERATORS_DAY_BOOK',
  ALERT_HISTORY: 'ALERT_HISTORY',
  // API Documentation
  API_DOCS: 'API_DOCS',
};

const ROUTE_TYPES = {
  ALLOW_IF_LOGGEDIN: 'ALLOW_IF_LOGGEDIN',
  ALLOW_IF_NOT_LOGGEDIN: 'ALLOW_IF_NOT_LOGGEDIN',
};

const PERMISSION_TYPES = {
  CREATE: 'CREATE',
  READ: 'READ',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  READ_LATEST_OTP: 'READ_LATEST_OTP',
  WALLET_PROCESS_FUNDS: 'WALLET_PROCESS_FUNDS',
  WALLET_TRANSACTIONS_READ: 'WALLET_TRANSACTIONS_READ',
  REFUND: 'REFUND',
  RELOAD: 'RELOAD',
  RAISE_DISPUTE: 'RAISE_DISPUTE',
  UPDATE_DISPUTE: 'UPDATE_DISPUTE',
  TRACK: 'TRACK',
  HEAVY_RELOAD: 'HEAVY_RELOAD',
  EXPORT: 'EXPORT',
  OPERATORS_CONFIG: 'OPERATORS_CONFIG',
  UPI_LOAD: 'UPI_LOAD',
  REPROCESS: 'REPROCESS',
};

const VIEW_TYPES = {
  TABLE: 'TABLE',
  GRID: 'GRID',
};

const API_RESPONSE_TYPES = {
  SUCCESS: 'success',
  FAILURE: 'failed',
};

export {
  ROUTE_TYPES,
  API_RESPONSE_TYPES,
  VIEW_TYPES,
  MODULES,
  PERMISSION_TYPES,
};
