import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
  display: block;
  width: calc(100% - 42px);
  height: auto;
  font-size: 14px;
  padding: 20px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  outline-color: #cacaca;
  background-color: #ffffff;
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};
  &:disabled {
    cursor: not-allowed;
    background-color: #fafafa;
  }
`;

const Numberinput = ({
  value = '',
  onChange = () => {},
  pattern = '[0-9.]*',
  maxLength = 1000,
  focus = false,
  textAlign = 'left',
  ...rest
}) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (focus) {
      inputRef.current.focus();
    }
  }, [focus]);

  return (
    <StyledInput
      type='text'
      pattern={pattern}
      value={value}
      onChange={(e) =>
        onChange(
          e.target.validity.valid && e.target.value.length <= maxLength
            ? e.target.value
            : value,
          e
        )
      }
      ref={inputRef}
      autoComplete='off'
      textAlign={textAlign}
      {...rest}
    />
  );
};

export default Numberinput;
