import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { withAppHOC } from '../../hoc';
import PageHeading from '../../components/PageHeader/PageHeader';
import OverlayLoader from '../../components/OverlayLoader/OverlayLoader';
import { Grid, GridItem } from '../../components/Grid';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  NumberInput,
  MessageBox,
  UpdateButton,
  ResetButton,
} from '../../components/FormElements';
import API from '../../api';
import { API_RESPONSE_TYPES } from '../../constants';

const PackagesUpdate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [packageName, setPackageName] = useState('');
  const [duration, setDuration] = useState('');
  const [originalFormData, setOriginalFormData] = useState({});
  const { id } = useParams();

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    const { packageName, duration } = originalFormData;
    setPackageName(packageName);
    setDuration(duration);
  };

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/packages/${id}`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const { packageName, duration } = data[0];
          setPackageName(packageName);
          setDuration(duration);
          setOriginalFormData({
            packageName,
            duration,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.put(`/packages/${id}`, {
      packageName,
      duration,
    })
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalFormData({
            packageName,
            duration,
          });
          setTimeout(() => {
            resetResponseData();
            window.location.href = '/packages';
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    readData();
  }, [readData]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form method='POST' action='#' onSubmit={onSubmit}>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label required>Package Name</Label>
                <TextInput
                  placeholder='Please enter package name'
                  value={packageName}
                  onChange={setPackageName}
                  maxLength={200}
                  autoComplete='off'
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Duration</Label>
                <NumberInput
                  placeholder='Please enter plan duration in days'
                  value={duration}
                  onChange={setDuration}
                  autoComplete='off'
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <UpdateButton disabled={isLoading} type='submit'>
              Update
            </UpdateButton>
            <ResetButton disabled={isLoading} type='button' onClick={resetForm}>
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(PackagesUpdate);
