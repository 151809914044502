import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faRotate } from '@fortawesome/free-solid-svg-icons';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { Button } from '../FormElements';

const StyledHeadingContainer = styled.section`
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 20px 0px;
  display: grid;
  grid-template-columns: auto max-content;
  @media (max-width: 600px) {
    grid-template-columns: auto;
    padding: 20px 0px 0px 0px;
  }
`;

const StyledPageHeadingContainer = styled.section`
  width: auto;
  height: auto;
`;

const StyledPageHeading = styled.h1`
  font-size: 24px;
  font-weight: normal;
  color: #000000;
  margin: 0px;
  padding: 0px;
  align-self: center;
`;

const StyledButtonsGroup = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  @media (max-width: 600px) {
    margin-top: 20px;
  }
  button {
    padding: 10px 30px;
    outline: 0px;
    transition: background-color 0.5s;
    background-color: #333e7a;
    @media (max-width: 600px) {
      width: 100%;
      margin: 0px 0px 20px 0px;
    }
    &:hover {
      background-color: #1e2759;
    }
    &.delete {
      background-color: red;
      margin: 0px 10px;
      @media (max-width: 600px) {
        margin: 0px 0px 20px 0px;
      }
      &:hover {
        background-color: #9d0101;
      }
    }
    &.export {
      background-color: green;
      margin: 0px 10px;
      @media (max-width: 600px) {
        margin: 0px 0px 20px 0px;
      }
      &:hover {
        background-color: #006200;
      }
    }
  }
  a {
    font-size: 18px;
    font-weight: normal;
    color: #ffffff;
    background-color: forestgreen;
    text-decoration: none;
    margin: 0px 10px;
    padding: 10px 30px;
    border-radius: 5px;
    cursor: pointer;
    outline: 0px;
    transition: background-color 0.5s;
    display: inline-block;
    @media (max-width: 600px) {
      width: 100%;
      margin: 0px 0px 20px 0px;
      text-align: center;
    }
    &:hover {
      background-color: #006200;
    }
    &.edit {
      background-color: #0095ff;
    }
  }
`;

const PageHeader = ({
  hideHeading,
  title,
  canCreate = false,
  createLink = '',
  createTitle = '',
  canReload = false,
  onClickReload = () => {},
}) => {
  return (
    <StyledHeadingContainer>
      {!hideHeading && title && (
        <StyledPageHeadingContainer>
          <StyledPageHeading>{title}</StyledPageHeading>
          <Breadcrumbs />
        </StyledPageHeadingContainer>
      )}
      <StyledButtonsGroup>
        {canCreate && (
          <NavLink
            to={createLink}
            title={createTitle}
            style={{ borderRadius: '50px' }}
          >
            <FontAwesomeIcon icon={faPlus} /> Create
          </NavLink>
        )}
        {canReload && (
          <Button onClick={onClickReload}>
            <FontAwesomeIcon icon={faRotate} /> Reload
          </Button>
        )}
      </StyledButtonsGroup>
    </StyledHeadingContainer>
  );
};
export default PageHeader;
