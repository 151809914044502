import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import Logo from '../components/Logo/Logo';
import Header from '../components/Header/Header';
import Navigation from '../components/Navigation/Navigation';
import Footer from '../components/Footer/Footer';
import API from '../api';
import { API_RESPONSE_TYPES } from '../constants';
import { authData } from '../utils';

const StyledMain = styled.main`
  width: 95%;
  max-width: 1800px;
  height: auto;
  margin: 0 auto;
  padding: 0px;
`;

const withAuthHOC = (WrappedComponent) => {
  return (props) => {
    return (
      <>
        <Logo />
        <WrappedComponent {...props} />
      </>
    );
  };
};

const withAppHOC = (WrappedComponent) => {
  return (props) => {
    const [showNav, setShowNav] = useState(false);
    const [profileName, setProfileName] = useState('-');

    const onClickLogout = (e) => {
      e.preventDefault();
      const { authToken } = authData.get();
      API.delete(`/auth/sessions/?sessionToken=${authToken}`);
      authData.clear();
      window.location.href = '/login';
    };

    const readProfile = useCallback(() => {
      API.get(`/auth/profile`).then((response) => {
        const { status, data } = response.data;
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setProfileName(`${data.firstName} ${data.lastName}` || 'N/A');
          authData.set('accountData', JSON.stringify(data));
        }
      });
    }, []);

    const onClickNavigationIcon = () => {
      setShowNav((showNav) => !showNav);
    };

    useEffect(() => {
      readProfile();
    }, [readProfile]);

    return (
      <>
        <Header
          profileName={profileName}
          onClickLogout={onClickLogout}
          showNav={showNav}
          setShowNav={onClickNavigationIcon}
        />
        <Navigation showNav={showNav} />
        <StyledMain>
          <WrappedComponent {...props} readProfile={readProfile} />
        </StyledMain>
        <Footer />
      </>
    );
  };
};

export { withAuthHOC, withAppHOC };
