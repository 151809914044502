import React from 'react';
import styled from 'styled-components';
import { withAppHOC } from '../../hoc';
import PageHeading from '../../components/PageHeader/PageHeader';
import { TableBuilder } from '../../components/TableElements';
import { StyledSpan } from '../../components/Styled';

const DownloadLink = styled.a`
  font-size: 18px;
  font-weight: normal;
  color: rgb(255, 255, 255);
  background-color: forestgreen;
  text-decoration: none;
  margin: 0px 10px;
  padding: 10px 30px;
  border-radius: 5px;
  cursor: pointer;
  outline: 0px;
  transition: background-color 0.5s ease 0s;
  display: inline-block;
  &:hover {
    background-color: rgb(0, 98, 0);
  }
`;

const Documentation = (props) => {
  const documentations = [
    {
      title: 'cPanel Configuration',
      description:
        'This documentation helps you understand how to point domain top server & creating mysql databases.',
      link: 'https://rechargesoftware.s3.ap-south-1.amazonaws.com/cpanel_configuration.pdf',
    },
    {
      title: 'Web APP Configuration',
      description:
        'This documentation helps you configuring your transaction routes & other modules.',
      link: 'https://rechargesoftware.s3.ap-south-1.amazonaws.com/web_app_configuration.pdf',
    },
    {
      title: 'Mobile APP Configuration',
      description:
        'This documentation helps you creating & generating react native mobile app for android.',
      link: 'https://rechargesoftware.s3.ap-south-1.amazonaws.com/mobile_app_configuration.pdf',
    },
  ];

  return (
    <>
      <PageHeading {...props} />
      <TableBuilder
        tableHeadings={[
          {
            title: 'Documentation',
            dataSelector: 'title',
            dataType: 'string',
            CellRenderer: (value, item) => (
              <>
                {value}
                {item.description && (
                  <StyledSpan style={{ textTransform: 'inherit' }}>
                    {item.description}
                  </StyledSpan>
                )}
              </>
            ),
          },
          {
            title: 'Resource',
            dataSelector: 'link',
            dataType: 'string',
            align: 'center',
            CellRenderer: (value, item) =>
              value ? (
                <DownloadLink
                  href={value}
                  title={item.title}
                  target={'_blank'}
                  rel='noreferrer'
                  style={{}}
                >
                  Download
                </DownloadLink>
              ) : (
                'N/A'
              ),
          },
        ]}
        tableData={documentations}
      />
    </>
  );
};

export default withAppHOC(Documentation);
